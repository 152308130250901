import React, { useEffect, useState, useContext } from "react";
import api from "../../api";
import Layout from "../../components/layout";
import { AuthContext } from "../../context/GlobalContextProvider";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Form, Button } from "antd";
import { navigate } from "gatsby";
import SEO from "../../components/seo";
import PrediosSelect from "./components/prediosCarrossel";
import dynamic from "@loadable/component";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import TextArea from "antd/lib/input/TextArea";

const MdEditor = dynamic(() => import("react-markdown-editor-lite"), {
  ssr: false,
});

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export default function Inicio() {
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [footer, setFooter] = useState(page?.footer);
  useEffect(() => {
    setLoading(true);
    getPage();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/inicio");
    setPage(data);
    setFooter(data.footer);
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSave(true);
    var data = new FormData();
    data.append(
      "data",
      JSON.stringify({
        predios: values.carousel,
        ...values,
        footer,
      })
    );

    await api({
      method: "PUT",
      url: "/inicio",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/`);
      }
    });

    setLoadingSave(false);
  };

  return (
    <Layout image="" loading={loading}>
      <SEO title="Bivver" description="Empreendimentos" />
      <Form onFinish={handleSubmit} initialValues={page}>
        <PrediosSelect
          carrossel
          initialValue={page?.predios}
          {...{ name: "carousel", Form }}
        />
        <h3 className="mt-16 mb-2">Conforto e segurança</h3>
        <Form.Item
          initialValue={page?.confortoSeguranca}
          name="confortoSeguranca"
        >
          <TextArea />
        </Form.Item>
        <h3 className="mt-16 mb-2">Localização estratégica</h3>
        <Form.Item
          initialValue={page?.localizacaoEstrategica}
          name="localizacaoEstrategica"
        >
          <TextArea />
        </Form.Item>
        <h3 className="mt-16 mb-2">Facilidades para seu investimento</h3>
        <Form.Item
          initialValue={page?.facilidadesInvestimento}
          name="facilidadesInvestimento"
        >
          <TextArea />
        </Form.Item>
        <h3 className="mt-16 mb-2">Footer</h3>
        <Form.Item>
          <MdEditor
            className="mb-10"
            value={footer}
            onChange={(e) => setFooter(e.text)}
            renderHTML={(text) => mdParser.render(text)}
            style={{ height: 500 }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
